import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import $ from 'jquery';
import { GlobalContext } from '../context/GlobalContext';

const GuestDynamicTable = () => {
    const socketRef = useRef(null);
    const navigate = useNavigate();
    const { globalUserOrganizationAccessName } = useContext(GlobalContext);
    const [steps, setSteps] = useState([
        { id: 1, title: 'Receive Bank Credit Memo via Email.', completed: true },
        { id: 2, title: 'Upload Bank Credit Memo to Create NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
        { id: 3, title: 'Fix NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
        { id: 4, title: 'Upload New NorCal Credit Memo to Portal', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 5, title: 'Generate IBank Documents', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 6, title: 'Send Documents to Ibank', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 7, title: 'Waiting for Ibank to Approve Loan', completed: false, wait: true },
        { id: 8, title: 'IBank Documents Approved', completed: false, status: true },
        { id: 9, title: 'Send 6 Questions Email', completed: false },
        { id: 10, title: 'Waiting for 6 Questions', completed: false, wait: true },
        { id: 11, title: '6 Questions Email Answered', completed: false, status: true },
        { id: 12, title: 'Update Forms with 6 Questions', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 13, title: 'Generate New Forms and Send to Bank', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 14, title: 'Waiting for Signature from Bank', completed: false, wait: true },
        { id: 15, title: 'Bank has Signed Forms and Dispersed Payment', completed: false, status: true },
        { id: 16, title: 'Notify Ms.Nikki that Loan is Completed', completed: false, action: true },
        { id: 17, title: 'Generate NorCal Invoice to Disperse the Payment', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 18, title: 'Loan Complete', completed: false, status: true },
    ]);

    useEffect(() => {
        socketRef.current = io.connect('https://api.formcopilot.app/dynamic_table', {
            withCredentials: true, // Ensures cookies (including JWT and CSRF tokens) are sent automatically
            transports: ['websocket']
        });

        const socket = socketRef.current;

        socket.on('connection_response', function(data) {
            console.log(data.message);
            const fieldsToFetch = ['_id', 'loan_name', 'progress_step'];
            socket.emit('get_items', { fields: fieldsToFetch, userOrganizationAccessName: globalUserOrganizationAccessName });
        });
        
        socket.on('items', function(data) {
            console.log('Received items:', data);
            $('#dynamicTable tbody').empty();
            
            // Reverse the data to display the newest item at the top
            const reversedData = data.reverse();

            reversedData.forEach(item => {
                appendRow(item);
            });
        });

        socket.on('new_item', function(item) {
            prependRow(item);
        });

        socket.on('update_item', function(item) {
            updateRow(item);
        });

        socket.on('delete_item', function(id) {
            removeRow(id);
        });

        socket.on('item', function(data) {
            console.log('Received item:', data);
            if (data.length > 0) {
                const item = data[0];
                navigate('/guest-pdf-viewer', { state: { pdfBase64NorCalTemplate: item.pdfBase64NorCalTemplate, docAiResultsNorCalTemplate: item.docAiResultsNorCalTemplate, pdfBase64: item.pdfBase64, jsonData: item.docAiResults, iframe: item.googleDocsIframe, formId: item._id, progressStep: item.progress_step } });
            }
        });

        socket.on('disconnect', function() {
            console.log('Disconnected from server');
        });

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    const appendRow = (item) => {
        const step = steps.find(step => step.id === item.progress_step);
        let status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';

        if (item.progress_step === 18) {
            status = `Completed ✅`;
        } else if (item.progress_step > 0 && item.progress_step < 18) {
            status = `Processing ⏳`;
        }
        
        const row = $(`
            <tr data-id="${item._id}">
                <td class="name-cell" data-id="${item._id}">${item.loan_name}</td>
                <td>${status}</td>
                <td>
                    <button class="action-button delete-button">Delete</button>
                </td>
            </tr>
        `);

        // Add click handler for the entire row except the delete button
        row.find('td').not(':last-child').on('click', () => {
            fetchAdditionalData(item._id);
        });

        $('#dynamicTable tbody').append(row);
    };

    const prependRow = (item) => {
        const step = steps.find(step => step.id === item.progress_step);
        let status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';

        if (item.progress_step === 18) {
            status = `Completed ✅`;
        } else if (item.progress_step > 0 && item.progress_step < 18) {
            status = `Processing ⏳`;
        }
        
        const row = $(`
            <tr data-id="${item._id}">
                <td class="name-cell" data-id="${item._id}">${item.loan_name}</td>
                <td>${status}</td>
                <td>
                    <button class="action-button delete-button">Delete</button>
                </td>
            </tr>
        `);

        // Add click handler for the entire row except the delete button
        row.find('td').not(':last-child').on('click', () => {
            fetchAdditionalData(item._id);
        });

        $('#dynamicTable tbody').prepend(row);
    };

    const updateRow = (item) => {
        let row = $(`tr[data-id='${item._id}']`);
        row.find('td').eq(0).text(item.loan_name);
        const step = steps.find(step => step.id === item.progress_step);
        let status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';

        if (item.progress_step === 18) {
            status = `Completed ✅`;
        } else if (item.progress_step > 0 && item.progress_step < 18) {
            status = `Processing ⏳`;
        }

        row.find('td').eq(1).text(status);
    };

    const removeRow = (id) => {
        $(`tr[data-id='${id}']`).remove();
    };

    const addItem = () => {
        const loan_name = prompt("Enter loan name:");
        console.log('user_organization_access_name:', globalUserOrganizationAccessName);
        socketRef.current.emit('add_item', { loan_name: loan_name, user_organization_access_name: globalUserOrganizationAccessName });
    };

    const deleteItem = (id) => {
        socketRef.current.emit('delete_item', id);
    };

    const fetchAdditionalData = (id) => {
        const socket = socketRef.current;
        const fieldsToFetch = ['pdfBase64', 'docAiResults', 'googleDocsIframe', '_id', 'loan_name', 'progress_step', 'pdfBase64NorCalTemplate', 'docAiResultsNorCalTemplate'];
        console.log('Fetching additional data for id:', id);
        socket.emit('get_items', { id, fields: fieldsToFetch });
    };

    window.deleteItem = deleteItem; // Make deleteItem globally accessible

    return (
        <div className="loan-directory">
            <h1>Loan Directory</h1>
            <button className="add-button" onClick={addItem}>Add New Loan</button>
            <input type="text" id="searchBar" placeholder="Search for a loan..." onKeyUp={searchLoans} />
            <table id="dynamicTable" className="loan-directory-table">
                <thead>
                    <tr>
                        <th>Loan Name</th>
                        <th>Current Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
            <style>{`
                .loan-directory {
                    text-align: center;
                    margin: 0 auto;
                    width: 80%;
                    padding: 20px;
                    background-color: #1e1e1e;
                    border-radius: 10px;
                    color: #ffffff;
                    position: relative;
                }

                .loan-directory h1 {
                    font-size: 24px;
                    margin-bottom: 20px;
                    color: #ffffff;
                }

                .add-button {
                    position: absolute;
                    top: 35px;
                    right: 0;
                    padding: 10px 20px;
                    background-color: #008CBA;
                    color: #ffffff;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                }

                #searchBar {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    font-size: 16px;
                    background-color: #2e2e2e;
                    color: #ffffff;
                }

                .loan-directory-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                    background-color: #333333;
                    color: #ffffff;
                }

                .loan-directory-table thead th {
                    background-color: #444444;
                    font-weight: bold;
                    padding: 10px;
                    border: 1px solid #555555;
                }

                .loan-directory-table tbody td {
                    padding: 10px;
                    border: 1px solid #555555;
                    cursor: pointer;
                }

                .action-button {
                    padding: 5px 10px;
                    margin: 2px;
                    border: none;
                    cursor: pointer;
                    font-size: 14px;
                    border-radius: 4px;
                    color: #ffffff;
                }

                .delete-button {
                    background-color: #007BFF;
                }
            `}</style>
        </div>
    );
};

const searchLoans = () => {
    const input = document.getElementById('searchBar').value.toLowerCase();
    const table = document.getElementById('dynamicTable');
    const rows = table.getElementsByTagName('tr');

    for (let i = 1; i < rows.length; i++) {
        const cells = rows[i].getElementsByClassName('name-cell');
        let match = false;

        if (cells[0]) {
            const cellValue = cells[0].textContent || cells[0].innerText;
            if (cellValue.toLowerCase().indexOf(input) > -1) {
                match = true;
            }
        }

        rows[i].style.display = match ? '' : 'none';
    }
};

export default GuestDynamicTable;
