import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import io from 'socket.io-client';
import { useWhatChanged } from '@simbathesailor/use-what-changed';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f1f1f1;
`;

const TaskContainer = styled.div`
  width: 25%;
`;

const PDFContainerWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PDFContainerRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const PDFContainer = styled.div`
  width: 48%;
  height: auto;
  border: 2px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  aspect-ratio: 8.5 / 11;
`;

const Box = styled.div`
  background-color: ${props => (props.$completed ? (props.$wait ? '#d3f9d8' : '#d3f9d8') : props.$wait ? '#fffacd' : props.$status ? '#add8e6' : '#e6e6fa')};
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Line = styled.div`
  width: 2px;
  background-color: #e6e6fa;
  position: absolute;
  left: 50%;
  height: 20px;
  top: 100%;
`;

const CheckMark = styled.span`
  font-size: 16px;
  color: green;
  margin-left: 10px;
`;

const HourGlass = styled.span`
  font-size: 16px;
  color: #d4af37; /* Darker yellow color */
  margin-left: 10px;
`;

const Popup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #dff0d8;
  color: #3c763d;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
`;

const IndividualLoanTracker = () => {
  const socketRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { pdfBase64: initialPdfUrl, jsonData: initialJsonData, iframe: initialIframeData, formId: initialFormId, progressStep: initialProgressStep, pdfBase64NorCalTemplate: pdfBase64NorCalTemplate, docAiResultsNorCalTemplate: docAiResultsNorCalTemplate } = location.state || {};
  
  const [initialData, setInitialData] = useState({
    pdfBase64: initialPdfUrl,
    jsonData: initialJsonData,
    iframe: initialIframeData,
    formId: initialFormId,
    progressStep: initialProgressStep,
    pdfBase64NorCalTemplate: pdfBase64NorCalTemplate,
    docAiResultsNorCalTemplate: docAiResultsNorCalTemplate,
  });

  const [steps, setSteps] = useState([
    { id: 1, key: '1', title: 'Recieve Bank Credit Memo via Email.', completed: true },
    { id: 2, key: '2', title: 'Upload Bank Credit Memo to Create NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
    { id: 3, key: '3', title: 'Fix NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
    { id: 4, key: '4', title: 'Upload New NorCal Credit Memo to Portal', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 5, key: '5', title: 'Generate IBank Documents', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 6, key: '6', title: 'Send Documents to Ibank', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 7, key: '7_wait', title: 'Waiting for Ibank to Approve Loan', completed: false, wait: true },
    { id: 8, key: '8', title: 'IBank Documents Approved', completed: false, status: true },
    { id: 9, key: '9', title: 'Send 6 Questions Email', completed: false },
    { id: 10, key: '10_wait', title: 'Waiting for 6 Questions', completed: false, wait: true },
    { id: 11, key: '11', title: '6 Questions Email Answered', completed: false, status: true },
    { id: 12, key: '12', title: 'Update Forms with 6 Questions', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 13, key: '13', title: 'Generate New Forms and Send to Bank', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 14, key: '14_wait', title: 'Waiting for Signature from Bank', completed: false, wait: true },
    { id: 15, key: '15_status', title: 'Bank has Signed Forms and Dispersed Payment', completed: false, status: true },
    { id: 16, key: '16_action', title: 'Notify Ms.Nikki that Loan is Completed', completed: false, action: true },
    { id: 17, key: '17', title: 'Generate NorCal Invoice to Disperse the Payment', completed: false, action: 'custom-pdf-viewer-tables' },
    { id: 18, key: '18', title: 'Loan Complete', completed: false, status: true },
  ]);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setSteps(s => s.map((step, index) => ({
      ...step,
      completed: index < initialData.progressStep
    })));
  }, [initialData.progressStep, initialData.formId]);

  const [pdfUrl1, setPdfUrl1] = useState(null);
  const [pdfUrl2, setPdfUrl2] = useState(null);

  const customFetch = (url, options = {}) => {
    return fetch(url, {
      ...options,
      credentials: 'include', // Ensures cookies are sent with every request
    });
  };

  const fetchAdditionalData = (id) => {
    const socket = socketRef.current;
    if (socket) {
      const fieldsToFetch = ['pdfBase64', 'docAiResults', 'googleDocsIframe', '_id', 'loan_name', 'progress_step', 'pdfBase64NorCalTemplate', 'docAiResultsNorCalTemplate'];
      console.log('Fetching additional data for id:', id);
      socket.emit('get_items', { id, fields: fieldsToFetch });
      
      socket.on('item', (data) => {
        if (data && data.length > 0) {
          const fetchedData = data[0];
          setInitialData({
            pdfBase64: fetchedData.pdfBase64,
            jsonData: fetchedData.docAiResults,
            iframe: fetchedData.googleDocsIframe,
            formId: fetchedData._id,
            progressStep: fetchedData.progress_step,
            pdfBase64NorCalTemplate: fetchedData.pdfBase64NorCalTemplate,
            docAiResultsNorCalTemplate: fetchedData.docAiResultsNorCalTemplate,
          });
        }
      });
    }
  };

  const fetchProgressStep = (id) => {
    const socket = socketRef.current;
    if (socket) {
      const fieldsToFetch = ['progress_step'];
      console.log('Fetching progress step for id:', id);
      socket.emit('get_items', { id, fields: fieldsToFetch });
      
      socket.on('item', (data) => {
        if (data && data.length > 0) {
          const fetchedData = data[0];
          setInitialData(prevData => ({
            ...prevData,
            progressStep: fetchedData.progress_step,
          }));
        }
      });
    }
  };

  useWhatChanged([initialData, initialFormId], "initialData, initialFormId")
  useEffect(() => {
    if (!initialData.pdfBase64 || !initialData.pdfBase64NorCalTemplate || !initialData.docAiResultsNorCalTemplate || !initialData.iframe) {
      if (initialFormId) {
        fetchAdditionalData(initialFormId);
      }
    }
  }, [initialData, initialFormId]);

  useEffect(() => {
    if (initialFormId) {
      fetchProgressStep(initialFormId);
    }
  }, [initialFormId]);

  useEffect(() => {
    if (initialData.pdfBase64) {
      try {
        const byteCharacters = atob(initialData.pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create object URL from Blob
        const objectUrl = URL.createObjectURL(blob);
        console.log('objectUrl:', objectUrl);
        setPdfUrl1(objectUrl);

        // Clean up the object URL when the component unmounts
        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Error converting base64 to Blob:', error);
      }
    } else {
      console.log('pdfBase64 is empty');
    }
  }, [initialData.pdfBase64]);

  useEffect(() => {
    if (initialData.pdfBase64NorCalTemplate) {
      try {
        const byteCharacters = atob(initialData.pdfBase64NorCalTemplate);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create object URL from Blob
        const objectUrl = URL.createObjectURL(blob);
        console.log('objectUrl:', objectUrl);
        setPdfUrl2(objectUrl);

        // Clean up the object URL when the component unmounts
        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Error converting base64 to Blob:', error);
      }
    } else {
      console.log('pdfBase64 is empty');
    }
  }, [initialData.pdfBase64NorCalTemplate]);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io.connect('https://api.formcopilot.app/dynamic_table', {
        withCredentials: true, // Ensures cookies (including JWT and CSRF tokens) are sent automatically
        transports: ['websocket']
      });

      socketRef.current.on('connection_response', (data) => {
        console.log(data.message);
        const fieldsToFetch = ['_id', 'loan_name', 'progress_step'];
        socketRef.current.emit('get_items', { fields: fieldsToFetch });
      });

      socketRef.current.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      socketRef.current.on('update_items_response', (response) => {
        console.log('Update Items Response:', response);
      });

      socketRef.current.on('email_sent', (response) => {
        console.log(response.msg);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      });
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.off('connection_response');
        socketRef.current.off('disconnect');
      }
    };
  });

  const toggleComplete = (id, action) => {
    const updatedSteps = steps.map((step) => ({
      ...step,
      completed: step.id <= id
    }));
    setSteps(updatedSteps);
  
    const updatedItem = {
      _id: initialData.formId,
      progress_step: id
    };
  
    if (socketRef.current) {
      socketRef.current.emit('update_items', { items: [updatedItem] }, (response) => {
        console.log('Update Items Response:', response);
      });
    } else {
      console.error("Socket connection not established.");
    }
  
    if (id === 5) {
      console.log('Opening Workflow with Chrome Extension');
      if (initialData.formId) {
        customFetch('https://api.formcopilot.app/create_nor_cal_form_web_app', {
          method: 'POST',
          body: JSON.stringify({ formId: initialData.formId }),
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log('Form received:', data);
        })
        .catch(error => {
          console.error('Error sending form data:', error);
        });
      } else {
        console.error('Form ID is missing.');
      }
    }

    if (id === 9) {
      console.log('Sending 6 questions email with action extension');
      if (initialData.formId) {
        customFetch('https://api.formcopilot.app/action_extension_send_6_questions_email', {
          method: 'POST',
          body: JSON.stringify({ formId: initialData.formId }),
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log('Form received:', data);
        })
        .catch(error => {
          console.error('Error sending form data:', error);
        });
      } else {
        console.error('Form ID is missing.');
      }
    }

    // if (id === 9) {
    //   console.log('Sending Email Chrome Extension');
    //   if (initialData.formId) {
    //     socketRef.current.emit('send_email', { formId: initialData.formId });
    //   } else {
    //     console.error('Form ID is missing.');
    //   }
    // }
  
    if (id === 12) {
      console.log('Updating 6 Questions...');
      if (initialData.formId) {
        customFetch('https://api.formcopilot.app/update_6_questions_web_app', {
          method: 'POST',
          body: JSON.stringify({ formId: initialData.formId }),
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log('6 Questions updated:', data);
        })
        .catch(error => {
          console.error('Error updating 6 Questions:', error);
        });
      } else {
        console.error('Form ID is missing.');
      }
    }
  
    if (action) {
      switch(action) {
        case 'pdf-viewer':
          navigate('/pdf-viewer', { state: { pdfBase64: initialData.pdfBase64, jsonData: initialData.jsonData, iframe: initialData.iframe, formId: initialData.formId } });
          break;
        case 'custom-pdf-viewer-tables':
          navigate('/custom-pdf-viewer-tables', { state: { pdfBase64: initialData.pdfBase64NorCalTemplate, jsonData: initialData.docAiResultsNorCalTemplate, formId: initialData.formId } });
          break;
        default:
          break;
      }
    }
  
    const currentStepIndex = steps.findIndex(step => step.id === id);
    const nextStep = steps[currentStepIndex + 1];
  
    if (nextStep && nextStep.wait) {
      setTimeout(() => toggleComplete(nextStep.id), 1000);
    }
  };

  useEffect(() => {
    const lastCompletedStepIndex = steps.findIndex(step => step.completed === false) - 1;
    const lastCompletedStep = steps[lastCompletedStepIndex];

    if (lastCompletedStep && lastCompletedStep.action) {
      const nextStep = steps[lastCompletedStepIndex + 1];
      if (nextStep && nextStep.status) {
        toggleComplete(nextStep.id);
      }
    }
  }, [steps]);

  return (
    <Container>
      <TaskContainer>
        {steps.map((step, index) => (
          <Box key={step.key} onClick={() => toggleComplete(step.id, step.action)} $completed={step.completed} $wait={step.wait} $status={step.status}>
            <span>{step.title}</span>
            {step.completed && !step.wait && <CheckMark>✔</CheckMark>}
            {step.completed && step.wait && <HourGlass>⏳</HourGlass>}
            {index < steps.length - 1 && <Line />}
          </Box>
        ))}
      </TaskContainer>

      <PDFContainerWrapper>
        <PDFContainerRow>
          <Worker workerUrl={pdfjsWorker}>
            <PDFContainer onClick={() => toggleComplete(2, 'pdf-viewer')}>
              {pdfUrl1 ? (
                <div style={{ height: '100%', width: '100%' }}>
                  <Viewer
                    fileUrl={pdfUrl1}
                    onDocumentLoad={() => console.log('Document loaded')}
                    defaultScale={SpecialZoomLevel.PageWidth}
                  />
                </div>
              ) : (
                <div>PDF 1</div>
              )}
            </PDFContainer>
            <PDFContainer onClick={() => toggleComplete(4, 'custom-pdf-viewer-tables')}>
              {pdfUrl2 ? (
                <div style={{ height: '100%', width: '100%' }}>
                  <Viewer
                    fileUrl={pdfUrl2}
                    onDocumentLoad={() => console.log('Document loaded')}
                    defaultScale={SpecialZoomLevel.PageWidth}
                  />
                </div>
              ) : (
                <div>PDF 2</div>
              )}
            </PDFContainer>
          </Worker>
        </PDFContainerRow>
      </PDFContainerWrapper>

      {showPopup && <Popup>6 Questions Email Sent</Popup>}
    </Container>
  );
};

export default IndividualLoanTracker;
