import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CustomPDFViewer from './components/CustomPDFViewer';
import CustomPDFViewerTables from './components/CustomPDFViewerTables';
import Auth from './components/Auth';
import FormsDynamicTable from './components/FormsDynamicTable';
import IndividualLoanTracker from './components/IndividualLoanTracker';
import AuthCallback from './components/AuthCallback';
import GuestDynamicTable from './components/GuestDynamicTable';
import GuestPDFViewer from './components/GuestPDFViewer';
import { ThemeProvider } from './context/ThemeContext';
import { GlobalProvider } from './context/GlobalContext';


const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessLevel, setAccessLevel] = useState(null);

    useEffect(() => {
        const idToken = localStorage.getItem('idToken');
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (idToken && accessToken && refreshToken) {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = (status, level) => {
        setIsAuthenticated(status);
        setAccessLevel(level);
    };

    return (
        <GlobalProvider>
            <ThemeProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<Auth onLogin={handleLogin} />} />
                        <Route path="/auth-callback" element={<AuthCallback onLogin={handleLogin} />} />
                        <Route
                            path="/pdf-viewer"
                            element={isAuthenticated && accessLevel !== 'guest' ? <CustomPDFViewer /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/custom-pdf-viewer-tables"
                            element={isAuthenticated && accessLevel !== 'guest' ? <CustomPDFViewerTables /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/dynamic-table"
                            element={isAuthenticated && accessLevel !== 'guest' ? <FormsDynamicTable /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/individual-loan-tracker"
                            element={isAuthenticated && accessLevel !== 'guest' ? <IndividualLoanTracker /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="guest-pdf-viewer"
                            element={isAuthenticated && accessLevel === 'guest' ? <GuestPDFViewer /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="guest-dynamic-table"
                            element={isAuthenticated && accessLevel === 'guest' ? <GuestDynamicTable /> : <Navigate to="/login" />}
                        />
                        <Route path="/" element={<Navigate to="/login" />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </GlobalProvider>
    );
};

export default App;
