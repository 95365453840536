import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTheme } from '../context/ThemeContext';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewertester = () => {
    const location = useLocation();
    const { pdfBase64: initialPdfUrl, jsonData: initialJsonData, iframe: initialIframeData, formId: initialFormId } = location.state || {};

    const [pdfUrl, setPdfUrl] = useState();
    const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
    const [jsonData, setJsonData] = useState([]);
    const [formId, setFormId] = useState(initialFormId || null);
    const [googleDocsUrl, setGoogleDocsUrl] = useState(initialIframeData || "https://docs.google.com/document/d/1YXu_Uu6Z5hdjqjRUX71PU1D_KPiKWe1Q/edit?usp=sharing&ouid=103844593516674711347&rtpof=true&sd=true");
    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [temporaryHighlights, setTemporaryHighlights] = useState([]);
    const [isHighlightVisible, setIsHighlightVisible] = useState(true);
    const [showAllHighlights, setShowAllHighlights] = useState(true);
    const { darkMode, toggleTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isApiLoading, setIsApiLoading] = useState(false);

    const themeStyles = {
        backgroundColor: darkMode ? '#121212' : '#FFFFFF',
        color: darkMode ? 'white' : 'black',
        borderColor: darkMode ? '#1e1e1e' : '#CCCCCC',
        buttonBackgroundColor: '#6A55FE'
    };

    const zoomPluginInstance = zoomPlugin();
    const highlightPluginInstance = highlightPlugin({
        renderHighlights: (props) => {
            return (
                <div>
                    {showAllHighlights && highlightedAreas.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: 'blue',
                                    opacity: 0.5,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                    {temporaryHighlights.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: 'blue',
                                    opacity: isHighlightVisible ? 0.5 : 0,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                </div>
            );
        },
    });

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    useEffect(() => {
        if (pdfBase64) {
            try {
                console.log('pdfBase64 exists:', pdfBase64.slice(0, 30)); // Log part of the base64 string for verification
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                
                // Create object URL from Blob
                const objectUrl = URL.createObjectURL(blob);
                console.log('objectUrl:', objectUrl);
                setPdfUrl(objectUrl);

                // Clean up the object URL when the component unmounts
                return () => URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('Error converting base64 to Blob:', error);
            }
        } else {
            console.log('pdfBase64 is empty');
        }
    }, [pdfBase64]);

    useEffect(() => {
        if (initialPdfUrl && initialJsonData) {
            setJsonData(initialJsonData.documents[0].fields);
            extractAndHighlightFromJson(initialJsonData.documents[0].fields);
        }
    }, [initialPdfUrl, initialJsonData]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPdfUrl(fileUrl);
            setFileName(file.name);
        }
        if (!file) return;
        setLoading(true);
        const fileUrl = URL.createObjectURL(file);
        setPdfUrl(fileUrl);

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            const base64Content = base64String.split(',')[1];
            const isDivisibleBy4 = base64Content.length % 4 === 0;
            let paddedBase64Content = base64Content;
            if (!isDivisibleBy4) {
                const missingPadding = 4 - (base64Content.length % 4);
                paddedBase64Content += '='.repeat(missingPadding);
            }
            const base64StringWithPadding = `${base64String.split(',')[0]},${paddedBase64Content}`;

            const token = localStorage.getItem('jwtAccessToken');

            axios({
                method: 'post',
                url: 'https://api.formcopilot.app/form_copilot',
                data: { 
                    body: base64StringWithPadding,
                    formId: formId,
                },
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                withCredentials: true // Ensure cookies are sent with the request
            })
                .then(response => {
                    setJsonData(response.data.documents[0].fields);
                    extractAndHighlightFromJson(response.data.documents[0].fields);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error posting PDF:', error);
                    setJsonData({ error: error.toString() });
                    setLoading(false);
                });
        };

        reader.onerror = error => {
            console.error('Error reading file:', error);
            setJsonData({ error: error.toString() });
        };
        reader.readAsDataURL(file);
    };

    const extractAndHighlightFromJson = (jsonData) => {
        const highlightedAreas = [];

        Object.entries(jsonData).forEach(([key, field]) => {
            if (field.boundingRegions) {
                field.boundingRegions.forEach(region => {
                    const polygon = region.polygon;
                    const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
                    const left = Math.min(x1, x2, x3, x4);
                    const top = Math.min(y1, y2, y3, y4);
                    const width = Math.max(x1, x2, x3, x4) - left;
                    const height = Math.max(y1, y2, y3, y4) - top;

                    highlightedAreas.push({
                        top: top / 11,
                        left: left / 8.5,
                        height: height / 11,
                        width: width / 8.5,
                        pageIndex: region.pageNumber - 1,
                        fieldKey: key,
                    });
                });
            }
        });

        setHighlightedAreas(highlightedAreas);
    };

    const handlePercentages = (x) => {
        return Math.round(x);
    };

    const handlePanelClick = (boundingRegions) => {
        setShowAllHighlights(false);
        setHighlightedAreas([]);
        if (boundingRegions && boundingRegions.length > 0) {
            const region = boundingRegions[0];
            const pageIndex = region.pageNumber - 1;
            jumpToPage(pageIndex);

            const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
            const left = Math.min(x1, x2, x3, x4);
            const top = Math.min(y1, y2, y3, y4);
            const width = Math.max(x1, x2, x3, x4) - left;
            const height = Math.max(y1, y2, y3, y4) - top;

            const highlightedArea = {
                top: top / 11,
                left: left / 8.5,
                height: height / 11,
                width: width / 8.5,
                pageIndex: pageIndex,
            };

            setTemporaryHighlights([highlightedArea]);
            setIsHighlightVisible(true);
            setTimeout(() => {
                setIsHighlightVisible(false);
                setTimeout(() => {
                    setTemporaryHighlights([]);
                }, 2000);
            }, 1000);
        }
    };

    const toggleShowAllHighlights = () => {
        setShowAllHighlights(!showAllHighlights);
    };

    useEffect(() => {
        if (showAllHighlights) {
            setTemporaryHighlights([]);
            setIsHighlightVisible(false);
        }
    }, [showAllHighlights]);

    const handleApiCall = () => {
        console.log('updating google docs');
        setIsApiLoading(true);
        const dataToSend = Object.entries(jsonData).map(([key, field]) => ({
            title: key,
            content: field.valueString
        }));


        axios({
            method: 'post',
            url: 'https://api.formcopilot.app/generate_google_docs',
            data: {
                formId: formId,
                dataToSend,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true // Ensure cookies are sent with the request
        })
            .then(response => {
                if (response.data && response.data.shareableLink) {
                    setGoogleDocsUrl(response.data.shareableLink);
                }
                setIsApiLoading(false);
            })
            .catch(error => {
                console.error('Error making API call:', error);
                setIsApiLoading(false);
            });
    };

    // New function to handle PDF download
    const handleDownloadPdf = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName || 'Credit Memo.pdf';
            link.click();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f1f1f1', overflow: 'hidden' }}>
            <iframe
                src={googleDocsUrl}
                style={{ width: '42%', height: '100%', border: 'none' }}
                title="Google Document"
            ></iframe>
            <div style={{ width: '38%', padding: '20px', position: 'relative' }}>
                <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="file-upload" style={{
                        cursor: 'pointer',
                        backgroundColor: '#6A55FE',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        margin: '0 10px 0 0'
                    }}>Upload Document</label>
                    <span style={{ flexGrow: 1, backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '8px' }}>
                        {fileName || 'No file chosen'}
                    </span>
                    <input
                        id="file-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept="application/pdf"
                        required
                    />
                    <button onClick={toggleShowAllHighlights}
                        style={{
                            marginLeft: '5px',
                            padding: '10px 20px',
                            backgroundColor: themeStyles.buttonBackgroundColor,
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer'
                        }}>
                        {showAllHighlights ? 'Hide Highlights' : 'Show Highlights'}
                    </button>
                    <button onClick={handleApiCall}
                        style={{
                            marginLeft: '5px',
                            padding: '10px 20px',
                            backgroundColor: themeStyles.buttonBackgroundColor,
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            position: 'relative'
                        }}>
                        {isApiLoading ? (
                            <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                            'Autofill Word Doc'
                        )}
                    </button>
                    <button onClick={handleDownloadPdf}
                        style={{
                            marginLeft: '5px',
                            padding: '10px 20px',
                            backgroundColor: themeStyles.buttonBackgroundColor,
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer'
                        }}>
                        Download PDF
                    </button>
                </div>
                {pdfUrl && (
                    <div style={{ position: 'relative', width: '100%', height: '80vh' }}>
                        <Worker workerUrl={pdfjsWorker}>
                            <div style={{ height: '100%' }}>
                                <Viewer
                                    fileUrl={pdfUrl}
                                    plugins={[zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]}
                                    onDocumentLoad={() => console.log('Document loaded')}
                                    defaultScale={.95}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', backgroundColor: 'white' }}>
                                    <ZoomOutButton />
                                    <ZoomPopover />
                                    <ZoomInButton />
                                </div>
                            </div>
                        </Worker>
                    </div>
                )}
            </div>
            <div style={{ width: '15%', marginTop: '16px' }}>
                <div style={{ width: '100%', overflowY: 'auto', height: '110vh', padding: '0px 20px' }} className='Details'>
                    {jsonData ? (
                        <>
                            {Object.entries(jsonData).map(([key, value], index) => (
                                <div
                                    key={index}
                                    onClick={() => handlePanelClick(value.boundingRegions)}
                                    style={{ padding: "10px", boxShadow: '0 4px 8px rgba(0,0,0,0.1)', backgroundColor: 'white', color: 'black', cursor: 'pointer', position: 'relative', width: '100%', margin: '5px 0px', borderRadius: '8px' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h4 style={{ textTransform: 'capitalize', margin: '0', color: '#6A55FE' }}>{key}</h4>
                                        <p>{handlePercentages(value.confidence * 100)}%</p>
                                    </div>
                                    <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{value.valueString}</p>
                                </div>
                            ))}
                        </>
                    ) :
                        <>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {loading ? <i className="fas fa-spinner fa-spin fa-3x"></i> : <h2>Please Upload Document</h2>}
                            </div>
                        </>}
                </div>
            </div>
        </div>
    );
};

export default PDFViewertester;
