import React, { createContext, useState } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
    const [globalUserOrganizationAccessName, setGlobalUserOrganizationAccessName] = useState(null);

    return (
        <GlobalContext.Provider value={{ globalUserOrganizationAccessName, setGlobalUserOrganizationAccessName }}>
            {children}
        </GlobalContext.Provider>
    );
};
