import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';

const AuthCallback = ({ onLogin }) => {
    const navigate = useNavigate();
    const { setGlobalUserOrganizationAccessName } = useContext(GlobalContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let accessLevel = urlParams.get('accessLevel');
        let userOrganizationAccessName = urlParams.get('userOrg');
        console.log('fetching and setting userOrganizationAccessName:', userOrganizationAccessName);
        setGlobalUserOrganizationAccessName(userOrganizationAccessName);

        fetch('/api/auth/status', { credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    accessLevel = response.headers.get('accessLevel') || accessLevel;
                    console.log('Final confirmed access level is:', accessLevel);

                    if (typeof onLogin === 'function') {
                        onLogin(true, accessLevel);
                    } else {
                        console.error('onLogin is not a function');
                    }

                    if (accessLevel === 'guest') {
                        navigate('/guest-dynamic-table');
                    } else {
                        navigate('/dynamic-table');
                    }
                } else {
                    navigate('/login');
                }
            })
            .catch(error => {
                console.error('Authentication check failed:', error);
                navigate('/login');
            });
    }, [navigate, onLogin, setGlobalUserOrganizationAccessName]);

    return (
        <div>
            <h1>Processing Authentication...</h1>
        </div>
    );
};

export default AuthCallback;
