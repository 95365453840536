import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import $ from 'jquery';
import moment from 'moment';
import { GlobalContext } from '../context/GlobalContext';

const FormsDynamicTable = () => {
    const socketRef = useRef(null);
    const navigate = useNavigate();
    const { globalUserOrganizationAccessName } = useContext(GlobalContext);
    const [steps, setSteps] = useState([
        { id: 1, title: 'Receive Bank Credit Memo via Email.', completed: true },
        { id: 2, title: 'Upload Bank Credit Memo to Create NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
        { id: 3, title: 'Fix NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
        { id: 4, title: 'Upload New NorCal Credit Memo to Portal', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 5, title: 'Generate IBank Documents', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 6, title: 'Send Documents to Ibank', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 7, title: 'Waiting for Ibank to Approve Loan', completed: false, wait: true },
        { id: 8, title: 'IBank Documents Approved', completed: false, status: true },
        { id: 9, title: 'Send 6 Questions Email', completed: false },
        { id: 10, title: 'Waiting for 6 Questions', completed: false, wait: true },
        { id: 11, title: '6 Questions Email Answered', completed: false, status: true },
        { id: 12, title: 'Update Forms with 6 Questions', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 13, title: 'Generate New Forms and Send to Bank', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 14, title: 'Waiting for Signature from Bank', completed: false, wait: true },
        { id: 15, title: 'Bank has Signed Forms and Dispersed Payment', completed: false, status: true },
        { id: 16, title: 'Notify Ms.Nikki that Loan is Completed', completed: false, action: true },
        { id: 17, title: 'Generate NorCal Invoice to Disperse the Payment', completed: false, action: 'custom-pdf-viewer-tables' },
        { id: 18, title: 'Loan Complete', completed: false, status: true },
    ]);

    useEffect(() => {
        socketRef.current = io.connect('https://api.formcopilot.app/dynamic_table', {
            withCredentials: true,
            transports: ['websocket']
        });

        const socket = socketRef.current;

        socket.on('connection_response', function(data) {
            console.log(data.message);
            const fieldsToFetch = ['_id', 'loan_name', 'progress_step', 'last_updated'];
            socket.emit('get_items', { fields: fieldsToFetch });
        });

        socket.on('items', function(data) {
            console.log('Received items:', data);
            $('#dynamicTable tbody').empty();

            // Reverse the data to display the newest item at the top
            const reversedData = data.reverse();

            reversedData.forEach(item => {
                appendRow(item);
            });
        });

        socket.on('new_item', function(item) {
            prependRow(item); // Prepend new items at the top
        });

        socket.on('update_item', function(item) {
            updateRow(item);
        });

        socket.on('delete_item', function(id) {
            console.log(`Server confirmed deletion of item with id: ${id}`);
            removeRow(id);
        });

        socket.on('item', function(data) {
            console.log('Received item:', data);
            if (data.length > 0) {
                const item = data[0];
                navigate('/individual-loan-tracker', { state: { pdfBase64NorCalTemplate: item.pdfBase64NorCalTemplate, docAiResultsNorCalTemplate: item.docAiResultsNorCalTemplate, pdfBase64: item.pdfBase64, jsonData: item.docAiResults, iframe: item.googleDocsIframe, formId: item._id, progressStep: item.progress_step } });
            }
        });

        socket.on('disconnect', function() {
            console.log('Disconnected from server');
        });

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    const formatLastUpdated = (lastUpdated, completed) => {
        if (completed) return 'Complete';

        const now = moment();
        const updatedTime = moment(lastUpdated);
        const diffMinutes = now.diff(updatedTime, 'minutes');
        const diffHours = now.diff(updatedTime, 'hours');
        const diffDays = now.diff(updatedTime, 'days');
        const diffWeeks = now.diff(updatedTime, 'weeks');

        if (diffMinutes < 60) return `${diffMinutes} Minutes Ago`;
        if (diffHours < 24) return `${diffHours} Hours Ago`;
        if (diffDays < 7) return `${diffDays} Days Ago`;
        if (diffWeeks < 4) return `${diffWeeks} Weeks Ago`;
        return updatedTime.format('MMM DD, YYYY');
    };

    const getStatusType = (step, nextStep) => {
        if (!step) return 'Unknown Status Type';
        
        // Check if the step is the last one and if it's completed
        if (step.id === steps.length && step.completed) {
            return 'Completed ✅';
        }
        
        if (step.action) {
            if (nextStep && nextStep.action) {
                return `Next Action: ${nextStep.title} 🏃‍♂️`;
            } else if (!nextStep) {
                return 'Final Action Required 🏁'; // Custom message for the last step
            }
            return 'Action Required 🏃‍♂️';
        }
        
        if (step.wait) return 'Waiting for Response ⏳';
        
        return nextStep ? `Next Action: ${nextStep.title} 🏃‍♂️` : 'Completed ✅';
    };

    const appendRow = (item) => {
        const step = steps.find(step => step.id === item.progress_step);
        const nextStep = steps.find(step => step.id === item.progress_step + 1);
        const status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';
        const statusType = step ? getStatusType(step, nextStep) : 'Unknown Status Type';
        const formattedLastUpdated = formatLastUpdated(item.last_updated, item.progress_step === 18);

        const row = $(`
            <tr data-id="${item._id}">
                <td class="name-cell">${item.loan_name}</td>
                <td>${status}</td>
                <td>${statusType}</td>
                <td>${formattedLastUpdated}</td>
                <td>
                    <button class="action-button edit-button">Edit</button>
                    <button class="action-button delete-button">Delete</button>
                </td>
            </tr>
        `);

        // Add click handler for the entire row except the action buttons
        row.find('td').not(':last-child').on('click', () => {
            fetchAdditionalData(item._id);
        });

        row.find('.edit-button').on('click', (e) => {
            e.stopPropagation();
            editItem(item._id);
        });

        row.find('.delete-button').on('click', (e) => {
            e.stopPropagation();
            deleteItem(item._id);
        });

        $('#dynamicTable tbody').append(row);
    };

    const prependRow = (item) => {
        const step = steps.find(step => step.id === item.progress_step);
        const nextStep = steps.find(step => step.id === item.progress_step + 1);
        const status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';
        const statusType = step ? getStatusType(step, nextStep) : 'Unknown Status Type';
        const formattedLastUpdated = formatLastUpdated(item.last_updated, item.progress_step === 18);

        const row = $(`
            <tr data-id="${item._id}">
                <td class="name-cell">${item.loan_name}</td>
                <td>${status}</td>
                <td>${statusType}</td>
                <td>${formattedLastUpdated}</td>
                <td>
                    <button class="action-button edit-button">Edit</button>
                    <button class="action-button delete-button">Delete</button>
                </td>
            </tr>
        `);

        // Add click handler for the entire row except the action buttons
        row.find('td').not(':last-child').on('click', () => {
            fetchAdditionalData(item._id);
        });

        row.find('.edit-button').on('click', (e) => {
            e.stopPropagation();
            editItem(item._id);
        });

        row.find('.delete-button').on('click', (e) => {
            e.stopPropagation();
            deleteItem(item._id);
        });

        $('#dynamicTable tbody').prepend(row);
    };

    const updateRow = (item) => {
        let row = $(`tr[data-id='${item._id}']`);
        row.find('td').eq(0).text(item.loan_name);
        const step = steps.find(step => step.id === item.progress_step);
        const nextStep = steps.find(step => step.id === item.progress_step + 1);
        const status = step ? `${step.title} (${item.progress_step}/18)` : 'Unknown Status';
        const statusType = step ? getStatusType(step, nextStep) : 'Unknown Status Type';
        const formattedLastUpdated = formatLastUpdated(item.last_updated, item.progress_step === 18);
        row.find('td').eq(1).text(status);
        row.find('td').eq(2).text(statusType);
        row.find('td').eq(3).text(formattedLastUpdated);
    };

    const removeRow = (id) => {
        console.log(`Attempting to remove row with id: ${id}`);
        const row = $(`tr[data-id='${id}']`);
        if (row.length > 0) {
            row.remove();
            console.log(`Row with id: ${id} removed.`);
        } else {
            console.log(`No row found with id: ${id}.`);
        }
    };

    const addItem = () => {
        const loan_name = prompt("Enter loan name:");
        socketRef.current.emit('add_item', { loan_name: loan_name, user_organization_access_name: globalUserOrganizationAccessName });
    };

    const editItem = (id) => {
        const loan_name = prompt("Enter new name:");
        socketRef.current.emit('update_item', { _id: id, loan_name: loan_name });
    };

    const deleteItem = (id) => {
        console.log(`Emitting delete_item event for id: ${id}`);
        socketRef.current.emit('delete_item', id);
    };

    const fetchAdditionalData = (id) => {
        const socket = socketRef.current;
        const fieldsToFetch = ['pdfBase64', 'docAiResults', 'googleDocsIframe', '_id', 'loan_name', 'progress_step', 'pdfBase64NorCalTemplate', 'docAiResultsNorCalTemplate'];
        console.log('Fetching additional data for id:', id);
        socket.emit('get_items', { id, fields: fieldsToFetch });
    };

    return (
        <div className="loan-directory">
            <h1>Loan Directory</h1>
            <button className="add-button" onClick={addItem}>Add New Loan</button>
            <input type="text" id="searchBar" placeholder="Search for a loan..." onKeyUp={searchLoans} />
            <table id="dynamicTable" className="loan-directory-table">
                <thead>
                    <tr>
                        <th>Loan Name</th>
                        <th>Current Status</th>
                        <th>Status Type</th>
                        <th>Last Updated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
            <style>{`
                .loan-directory {
                    text-align: center;
                    margin: 0 auto;
                    width: 80%;
                    padding: 20px;
                    background-color: #1e1e1e;
                    border-radius: 10px;
                    color: #ffffff;
                    position: relative;
                }

                .loan-directory h1 {
                    font-size: 24px;
                    margin-bottom: 20px;
                    color: #ffffff;
                }

                .add-button {
                    position: absolute;
                    top: 35px;
                    right: 0;
                    padding: 10px 20px;
                    background-color: #008CBA;
                    color: #ffffff;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                }

                #searchBar {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    font-size: 16px;
                    background-color: #2e2e2e;
                    color: #ffffff;
                }

                .loan-directory-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                    background-color: #333333;
                    color: #ffffff;
                }

                .loan-directory-table thead th {
                    background-color: #444444;
                    font-weight: bold;
                    padding: 10px;
                    border: 1px solid #555555;
                }

                .loan-directory-table tbody td {
                    padding: 10px;
                    border: 1px solid #555555;
                    cursor: pointer;
                }

                .action-button {
                    padding: 5px 10px;
                    margin: 2px;
                    border: none;
                    cursor: pointer;
                    font-size: 14px;
                    border-radius: 4px;
                    color: #ffffff;
                }

                .edit-button {
                    background-color: #4CAF50;
                }

                .delete-button {
                    background-color: #f44336;
                }
            `}</style>
        </div>
    );
};

const searchLoans = () => {
    const input = document.getElementById('searchBar').value.toLowerCase();
    const table = document.getElementById('dynamicTable');
    const rows = table.getElementsByTagName('tr');

    for (let i = 1; i < rows.length; i++) {
        const cells = rows[i].getElementsByTagName('td');
        let match = false;

        for (let j = 0; j < cells.length; j++) {
            if (cells[j]) {
                const cellValue = cells[j].textContent || cells[j].innerText;
                if (cellValue.toLowerCase().indexOf(input) > -1) {
                    match = true;
                    break;
                }
            }
        }

        rows[i].style.display = match ? '' : 'none';
    }
};

export default FormsDynamicTable;
